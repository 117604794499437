.AlertDialog {
  &-title {
    font-weight: 400;
    font-size: 24px;
  }

  &-text {
    font-weight: 400;
    font-size: 16px;
    color: #383838;
    padding-bottom: 15px;
  }

  &-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
}
