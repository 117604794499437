.MainHeader {
  box-shadow: none;

  &-toolbar {
    justify-content: space-between;
    flex-flow: unset;
  }

  &-logoWrapper {
    display: flex;
    align-items: center;
    flex-grow: 0;
  }

  &-logo {
    max-height: 60px;
    max-width: 150px;
  }

  &-profileIconWrapper {
    display: flex;
  }
  
  @media (width <= 600px) {
    &-toolbar {
      flex-flow: wrap;
    }

    &-logoWrapper {
      flex-grow: 1;
    }
  }
}
