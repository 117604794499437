.ConnectLoading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &-text {
    font-size: 1.25rem;
    margin-top: 12px;
  }
}
