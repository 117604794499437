.ErrorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  margin-top: -50px;

  &-icon.MuiSvgIcon-root {
    font-size: 3.125rem;
  }

  &-text {
    margin: 12px;
    font-size: 1.25rem;
    text-align: center;
  }

  &-link.MuiLink-root {
    margin-top: 12px;
    text-transform: uppercase;
  }
}
